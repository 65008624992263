import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    // {
    //     id: 3,
    //     label: 'Approval Setting',
    //     icon: 'mdi mdi-application-settings',
    //     link: '/tables/approvalsettings',
    //     visible:true
    // },
    // {
    //     id: 174,
    //     label: 'Expense Request',
    //     icon: 'mdi mdi-file-document-edit',
    //     link:'/tables/expenserequest',
    //     visible:true
        
    // },
   
    // {
    //     id: 230,
    //     label: 'Approvals',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/costingtemplatelist'
    // },

    // {
    //     id: 234,
    //     label: 'Site',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/sites',
    //     parentId: 1
    // },
    // {
    //     id: 233,
    //     label: 'Site Types',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/sitetypes',
    //     parentId: 1
    // },
    // {
    //     id: 235,
    //     label: 'Expense Head',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/expensehead',
    //     parentId: 1
    // },
    // {
    //     id: 236,
    //     label: 'Supplier',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/supplier',
    //     parentId: 1
    // },

    // {
    //     id:232,
    //     label: 'Masters',
    //     icon: 'bx bx-home-circle',
    //     subItems: 
    //     [
    //         {
    //             id: 234,
    //             label: 'Site',
    //             link: '/tables/sites',
    //             parentId: 1
    //         },
    //         {
    //             id: 233,
    //             label: 'Site Types',
    //             link: '/tables/sitetypes',
    //             parentId: 1
    //         },
    //         {
    //             id: 235,
    //             label: 'Expense Head',
    //             link: '/tables/expensehead',
    //             parentId: 1
    //         },
    //         {
    //             id: 236,
    //             label: 'Supplier',
    //             link: '/tables/supplier',
    //             parentId: 1
    //         },
    //         {
    //             id: 237,
    //             label: 'User Types',
    //             link: '/tables/usertype',
    //             parentId: 1
    //         }
            
    //     ]


    // },
    
   
    // {
    //     id: 231,
    //     label: 'Reports',
    //     icon: 'bx bx-home-circle',
    //     // link: '/tables/allmasters'
    // },
    // {
    //     id: 126,
    //     label: 'Payments',
    //     icon: 'bx bx-buoy',
    //     // subItems: [
    //     //     {
    //     //         id: 138,
    //     //         label: 'Item Category',
    //     //         link: '/tables/itemCategory',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 132,
    //     //         label: 'Item',
    //     //         link: '/tables/items',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 133,
    //     //         label: 'Item Varieties',
    //     //         link: '/tables/item-variety',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 134,
    //     //         label: 'Item Qty Settings',
    //     //         link: '/tables/item-settings',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 135,
    //     //         label: 'Country Master',
    //     //         link: '/tables/country',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 136,
    //     //         label: 'State Master',
    //     //         link: '/tables/state',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 137,
    //     //         label: 'City Master',
    //     //         link: '/tables/city',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 139,
    //     //         label: 'Company Type',
    //     //         link: '/tables/companytype',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 140,
    //     //         label: 'Item Grade',
    //     //         link: '/tables/itemgrade',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 141,
    //     //         label: 'Seller Category',
    //     //         link: '/tables/sellercategory',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 142,
    //     //         label: 'Document Type',
    //     //         link: '/tables/documenttype',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 143,
    //     //         label: 'Privileges',
    //     //         link: '/tables/privileges',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 144,
    //     //         label: 'Privilege Modules',
    //     //         link: '/tables/privilegemodules',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 145,
    //     //         label: 'Documents',
    //     //         link: '/tables/documents',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 146,
    //     //         label: 'Logistic Process',
    //     //         link: '/tables/logisticprocess',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 147,
    //     //         label: 'Logistic Sub Process',
    //     //         link: '/tables/logisticsubprocess',
    //     //         parentId: 1
    //     //     }
            
    //     // ]
    // },
    
    // {
    //     id: 231,
    //     label: 'Add User',
    //     icon: 'bx bx-buoy',
    // },
    
    // {
    //     id: 175,
    //     label: 'Purchase Indent',
    //     isTitle: true,
    //     visible:true
    // },
    // {
    //     id: 178,
    //     label: 'Add Purchase Indent',
    //     icon: 'bx-store',
    //     link:'/tables/addpurchaseindent',
    //     visible:true
        
    // },
    // {
    //     id: 179,
    //     label: 'Purchase Request',
    //     icon: 'mdi mdi-cart',
    //     link:'/tables/purchaseindent',
    //     visible:true
        
    // },
    // {
    //     id: 188,
    //     label: 'Disburse Request',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/disbursementlist',
    //     visible:true
        
    // },
    // {
    //         id: 189,
    //         label: 'Suppliers',
    //         icon: 'mdi mdi-truck-delivery',
    //         link:'/tables/suppliers',
    //         visible:true
    //     },

        {
            id: 191,
            label: 'Quotation Requests',
            icon: 'bx bx-copy-alt',
            link:'/tables/quotationlist',
            visible:true
        },
        {
            id: 192,
            label: 'Purchase Order',
            icon: 'mdi mdi-cart-check',
            link:'/tables/purchaseorder',
            visible:true
        },
    // {
    //     id: 56,
    //     label: 'USERS',
    //     isTitle: true
    // },
    // {
    //     id: 57,
    //     label: 'List of Vendors',
    //     icon: 'bx-user-circle',
    //     // badge: {
    //     //     variant: 'success',
    //     //     text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     // },
    //     // link:'/tables/users'
    // },
    // {
    //     id: 80,
    //     label: 'COMPANY',
    //     isTitle: true
    // },
    // {
    //     id: 81,
    //     label: 'Companies',
    //     icon: 'bx bx-buildings',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     },
    //     link:'/ui/alerts'
    // },
    // {
    //     id: 82,
    //     label: 'ORDER',
    //     isTitle: true
    // },
    // {
    //     id: 83,
    //     label: 'Orders',
    //     icon: 'bx bx-food-menu',
    //     link:'/tables/orders'
    // },
    // {
    //     id: 84,
    //     label: 'SUPPORT',
    //     isTitle: true
    // },
    // {
    //     id: 85,
    //     label: 'Assist',
    //     icon: 'bx bx-chat',
    //     link:'/ui/alerts'
    // },
    // {
    //     id: 86,
    //     label: 'REPORTS',
    //     isTitle: true
    // },
    // {
    //     id: 87,
    //     label: 'Summary Report',
    //     icon: 'bx bxs-report',
    //     link:'/ui/blogs'
    // },
];


export const MENU2: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard'
    },
    // {
    //     id: 3,
    //     label: 'Approval Setting',
    //     icon: 'mdi mdi-application-settings',
    //     link: '/tables/approvalsettings',
    //     visible:true
    // },
   
   
    // {
    //     id: 230,
    //     label: 'Approvals',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/costingtemplatelist'
    // },

    // {
    //     id: 234,
    //     label: 'Site',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/sites',
    //     parentId: 1
    // },
    // {
    //     id: 233,
    //     label: 'Site Types',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/sitetypes',
    //     parentId: 1
    // },
    // {
    //     id: 235,
    //     label: 'Expense Head',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/expensehead',
    //     parentId: 1
    // },
    // {
    //     id: 236,
    //     label: 'Supplier',
    //     icon: 'bx bx-home-circle',
    //     link: '/tables/supplier',
    //     parentId: 1
    // },

    // {
    //     id:232,
    //     label: 'Masters',
    //     icon: 'bx bx-home-circle',
    //     subItems: 
    //     [
    //         {
    //             id: 234,
    //             label: 'Site',
    //             link: '/tables/sites',
    //             parentId: 1
    //         },
    //         {
    //             id: 233,
    //             label: 'Site Types',
    //             link: '/tables/sitetypes',
    //             parentId: 1
    //         },
    //         {
    //             id: 235,
    //             label: 'Expense Head',
    //             link: '/tables/expensehead',
    //             parentId: 1
    //         },
    //         {
    //             id: 236,
    //             label: 'Supplier',
    //             link: '/tables/supplier',
    //             parentId: 1
    //         },
    //         {
    //             id: 237,
    //             label: 'User Types',
    //             link: '/tables/usertype',
    //             parentId: 1
    //         }
            
    //     ]


    // },
    
   
    // {
    //     id: 231,
    //     label: 'Reports',
    //     icon: 'bx bx-home-circle',
    //     // link: '/tables/allmasters'
    // },
    // {
    //     id: 126,
    //     label: 'Payments',
    //     icon: 'bx bx-buoy',
    //     // subItems: [
    //     //     {
    //     //         id: 138,
    //     //         label: 'Item Category',
    //     //         link: '/tables/itemCategory',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 132,
    //     //         label: 'Item',
    //     //         link: '/tables/items',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 133,
    //     //         label: 'Item Varieties',
    //     //         link: '/tables/item-variety',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 134,
    //     //         label: 'Item Qty Settings',
    //     //         link: '/tables/item-settings',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 135,
    //     //         label: 'Country Master',
    //     //         link: '/tables/country',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 136,
    //     //         label: 'State Master',
    //     //         link: '/tables/state',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 137,
    //     //         label: 'City Master',
    //     //         link: '/tables/city',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 139,
    //     //         label: 'Company Type',
    //     //         link: '/tables/companytype',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 140,
    //     //         label: 'Item Grade',
    //     //         link: '/tables/itemgrade',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 141,
    //     //         label: 'Seller Category',
    //     //         link: '/tables/sellercategory',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 142,
    //     //         label: 'Document Type',
    //     //         link: '/tables/documenttype',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 143,
    //     //         label: 'Privileges',
    //     //         link: '/tables/privileges',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 144,
    //     //         label: 'Privilege Modules',
    //     //         link: '/tables/privilegemodules',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 145,
    //     //         label: 'Documents',
    //     //         link: '/tables/documents',
    //     //         parentId: 1
    //     //     }
    //     //     ,
    //     //     {
    //     //         id: 146,
    //     //         label: 'Logistic Process',
    //     //         link: '/tables/logisticprocess',
    //     //         parentId: 1
    //     //     },
    //     //     {
    //     //         id: 147,
    //     //         label: 'Logistic Sub Process',
    //     //         link: '/tables/logisticsubprocess',
    //     //         parentId: 1
    //     //     }
            
    //     // ]
    // },
    
    // {
    //     id: 231,
    //     label: 'Add User',
    //     icon: 'bx bx-buoy',
    // },
    // {
    //     id: 174,
    //     label: 'Expense Request',
    //     icon: 'bx-store',
    //     link:'/tables/expenserequest'
        
    // },
    // {
    //     id: 56,
    //     label: 'USERS',
    //     isTitle: true
    // },
    // {
    //     id: 57,
    //     label: 'List of Vendors',
    //     icon: 'bx-user-circle',
    //     // badge: {
    //     //     variant: 'success',
    //     //     text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     // },
    //     // link:'/tables/users'
    // },
    // {
    //     id: 80,
    //     label: 'COMPANY',
    //     isTitle: true
    // },
    // {
    //     id: 81,
    //     label: 'Companies',
    //     icon: 'bx bx-buildings',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     },
    //     link:'/ui/alerts'
    // },
    // {
    //     id: 82,
    //     label: 'ORDER',
    //     isTitle: true
    // },
    // {
    //     id: 83,
    //     label: 'Orders',
    //     icon: 'bx bx-food-menu',
    //     link:'/tables/orders'
    // },
    // {
    //     id: 84,
    //     label: 'SUPPORT',
    //     isTitle: true
    // },
    // {
    //     id: 85,
    //     label: 'Assist',
    //     icon: 'bx bx-chat',
    //     link:'/ui/alerts'
    // },
    // {
    //     id: 86,
    //     label: 'REPORTS',
    //     isTitle: true
    // },
    // {
    //     id: 87,
    //     label: 'Summary Report',
    //     icon: 'bx bxs-report',
    //     link:'/ui/blogs'
    // },
];


export const SETTINGS : MenuItem[] =[


    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true
    },
   
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    // {
    //     id: 3,
    //     label: 'Approval Setting',
    //     icon: 'mdi mdi-application-settings',
    //     link: '/tables/approvalsettings',
    //     visible:true
    //   },
    // {
    //     id: 174,
    //     label: 'Expense Request',
    //     icon: 'mdi mdi-file-document-edit',
    //     link:'/tables/expenserequest',
    //     visible:true
        
    // },
    {
        id:232,
        label: 'Settings',
        icon: 'bx bxs-dashboard',
        visible:true,
        subItems: 
        [
            {
                id: 234,
                label: 'Privileges',
                link: '/tables/privileges',
                parentId: 1,
                visible:true
            },
            {
                id: 233,
                label: 'Privilege Module',
                link: '/tables/privilegemodules',
                parentId: 1,
                visible:true
            },
            
            
            
        ]


    },
    {
        id: 245,
        label: 'Purchase Indent',
        isTitle: true,
        visible:true
    },
    {
        id: 246,
        label: 'Add Purchase Indent',
        icon: 'bx-store',
        link:'/tables/addpurchaseindent',
        visible:true
        
    },
    {
        id: 247,
        label: 'Purchase Request',
        icon: 'mdi mdi-cart',
        link:'/tables/purchaseindent',
        visible:true
        
    },
    {
        id: 248,
        label: 'Disburse Request',
        icon: 'mdi mdi-cash-multiple',
        link:'/tables/disbursementlist',
        visible:true
        
    },
];


export const MASTERS : MenuItem[] =[


    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        visible:true
    },
   
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        link: '/dashboard',
        visible:true
    },
    // {
    //     id: 174,
    //     label: 'Expense Request',
    //     icon: 'mdi mdi-file-document-edit',
    //     link:'/tables/expenserequest',
    //     visible:true
        
    // },
    {
        id:232,
        label: 'Masters',
        icon: 'bx bxs-dashboard',
        visible:true,
        subItems: 
        [
            {
                id: 234,
                label: 'Site',
                link: '/tables/sites',
                parentId: 1,
                visible:true
            },
            {
                id: 233,
                label: 'Site Types',
                link: '/tables/sitetypes',
                parentId: 1,
                visible:true
            },
            {
                id: 235,
                label: 'Expense Head',
                link: '/tables/expensehead',
                parentId: 1,
                visible:true
            },
            {
                id: 236,
                label: 'Supplier',
                link: '/tables/supplier',
                parentId: 1,
                visible:true
            },
            {
                id: 245,
                label: 'Supplier Category',
                link: '/tables/suppliercategory',
                parentId: 1,
                visible:true
            }, {
                id: 246,
                label: 'Supplier Type',
                link: '/tables/suppliertype',
                parentId: 1,
                visible:true
            },
            {
                id: 237,
                label: 'User Types',
                link: '/tables/usertype',
                parentId: 1,
                visible:true
            },
            {
                id: 238,
                label: 'Account User',
                link: '/tables/accountuser',
                parentId: 1,
                visible:true
            },
            {
                id: 239,
                label: 'Payment Type',
                link: '/tables/paymentoption',
                parentId: 1,
                visible:true
            },
            {
                id: 240,
                label: 'Documents',
                link: '/tables/documents',
                parentId: 1,
                visible:true
            },
            {
                id: 241,
                label: 'Brands',
                link: '/tables/brands',
                parentId: 1,
                visible:true
            },
            {
                id: 242,
                label: 'Item Category',
                link: '/tables/itemcategory',
                parentId: 1,
                visible:true
            },
            {
                id: 243,
                label: 'UOM',
                link: '/tables/uom',
                parentId: 1,
                visible:true
            },
            {
                id: 244,
                label: 'Items',
                link: '/tables/items',
                parentId: 1,
                visible:true

            },
            {
                id: 264,
                label: 'Permission View',
                link: '/tables/permissionview',
                parentId: 1,
                visible:true

            },
            {
                id: 265,
                label: 'Add Approval Setting',
                link: '/tables/approvalsettings',
                parentId: 1,
                visible:true

            },
            {
                id: 266,
                label: 'Approval Setting List',
                link: '/tables/approvallist',
                parentId: 1,
                visible:true

            },
            {
                id: 267,
                label: 'Privilege Module',
                link: '/tables/privilegemodules',
                parentId: 1,
                visible:true

            },
            // {
            //     id: 266,
            //     label: 'Privileges',
            //     link: '/tables/privileges',
            //     parentId: 1,
            //     visible:true

            // }
            
            
        ]


    },
    // {
    //     id: 245,
    //     label: 'Purchase Indent',
    //     isTitle: true,
    //     visible:true
    // },
    // {
    //     id: 246,
    //     label: 'Add Purchase Indent',
    //     icon: 'bx-store',
    //     link:'/tables/addpurchaseindent',
    //     visible:true
        
    // },
    // {
    //     id: 247,
    //     label: 'Purchase Request',
    //     icon: 'mdi mdi-cart',
    //     link:'/tables/purchaseindent',
    //     visible:true
        
    // },
    // {
    //     id: 248,
    //     label: 'Disburse Request',
    //     icon: 'mdi mdi-cash-multiple',
    //     link:'/tables/disbursementlist',
    //     visible:true
        
    // },
];


