import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {TokenStorageService} from './token.service'

@Injectable()
export class TokenAuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const access_token = this.tokenService.getTokenId();
    console.log(access_token+"interceptor")
    
    
    const tokenizedRequest = request.clone(
      {
        headers:request.headers.append(
          'Authorization', access_token
        )
        // setHeaders:{
        //   'Authorization': access_token

        // }
      }
    )
    return next.handle(tokenizedRequest);
  }
}
