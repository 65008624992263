import { Directive } from '@angular/core';

@Directive({
  selector: '[appBlockpaste]'
})
export class BlockpasteDirective {

  constructor() { }

}
